/**
 * @type import('./locales').Locale[]
 */
const countries = ["cn", "en", "jp"];

/**
 * @type Record<import('./locales').Locale, import('./languages').LanguageToken>
 */
const countryToLanguageMap = {
  cn: "zh",
  en: "en",
  jp: "ja",
  kr: "ko",
};

/**
 * @type Record<import('./languages').LanguageToken, import('./locales').Locale>
 */
const languageToCountryMap = {
  en: "en",
  ja: "jp",
  ko: "kr",
  zh: "cn",
};

/**
 * @type import('./locales').Locale
 */
const defaultCountry = "en";

/**
 * @param {*} locale
 * @returns {locale is import('./locales').Locale} boolean
 */
const isLocale = (locale) => {
  return countries.includes(locale);
};

module.exports = {
  countries,
  countryToLanguageMap,
  defaultCountry,
  isLocale,
  languageToCountryMap,
};
