import { css } from "@emotion/react";
import type { Property } from "csstype";
import type { ReactNode } from "react";

import {
  BaseLayoutProps,
  baseStylePropKeys,
  generateResponsiveCSS,
  separateStyleProps,
  WithResponsive,
} from "@10xdev/utils";

interface GridItemProps extends BaseLayoutProps {
  as?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
  className?: string;
  gridColumn?: WithResponsive<Property.GridColumn>;
  gridColumnEnd?: WithResponsive<Property.GridColumnEnd>;
  gridColumnStart?: WithResponsive<Property.GridColumnStart>;
  gridRow?: WithResponsive<Property.GridRow>;
  gridRowEnd?: WithResponsive<Property.GridRowEnd>;
  gridRowStart?: WithResponsive<Property.GridRowStart>;
}

function GridItem({
  as: Component = "div",
  children,
  className,
  ...props
}: GridItemProps) {
  const gridItemStylePropKeys = [
    ...baseStylePropKeys,
    "gridColumn",
    "gridColumnEnd",
    "gridColumnStart",
    "gridRow",
    "gridRowEnd",
    "gridRowStart",
  ];
  const { styles, rest } = separateStyleProps(props, gridItemStylePropKeys);
  const cssString = generateResponsiveCSS({ ...styles });
  return (
    <Component
      className={className}
      css={css`
        ${cssString}
      `}
      {...rest}
    >
      {children}
    </Component>
  );
}

export default GridItem;
